import validator from 'validator'
import _ from 'lodash'

// assets
import Strings from '../assets/strings.json'

export default (val, valSettings, fields) => {
  let msg = false
  val = val.toString()
  _.each(valSettings, (setting, rule) => {
    switch (rule){
      case 'required':
        msg = validator.isEmpty(val) ? Strings.required : false
        break
      case 'equal': 
        msg = val !== setting ? Strings.mustMatch : false
        break
      case 'integer':
        msg = !validator.isInt(val) ? Strings.int : false
        break
      case 'min':
        msg = validator.isInt(val, {lt: parseInt(setting)}) ? Strings.min : false
        break
      case 'max':
        msg = validator.isInt(val, {gt: parseInt(setting)}) ? Strings.max : false
        break
      case 'minStr':
        msg = val.length < setting ? Strings.minStr + ' ' + setting : false
        break
      case 'maxStr':
        msg = val.length > setting ? Strings.minStr + ' ' + setting : false
        break
      case 'number':
        msg = /^d+$/.test(val) ? Strings.number : false
        break
      case 'lessOrEqual':
        const s = parseInt(fields[setting].value)
        if (fields[setting].value === 'No sensor'){
          break
        }

        if (s) {
          msg = validator.isInt(val, {gt: s}) ? `${Strings.max} maximum ${fields[setting].value}` : false
        }else {
          msg = `First select ${fields[setting].title}`
        }
        break
      default:
        msg = false
        break
    }

    if (msg){
      return false
    }
  })
  
  return msg
}