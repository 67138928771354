import { ADD_USER, GET_USER, ERROR_USER } from '../actions/actionTypes'

const initialState = {
  newUser: false,
  userData: false,
  error: false
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case ERROR_USER:
      return {
        ...state,
        error: action.error
      }
    case GET_USER:
      return {
        ...state,
        newUser: false,
        userData: action.userData,
        error: false
      }
    case ADD_USER:
      return {
        ...state,
        newUser: true,
        error: false
      }
    default: 
      return state
  }
}

export default reducer