import { GET_USER, ERROR_USER } from './actionTypes'
import { UIStartLoading, UIStopLoading, modalClose } from './index'
import Axios from 'axios'

export const getUser = () => {
  return dispatch => {
    dispatch(UIStartLoading())
    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/get-user',
      method: 'GET'
    }).then(res => {
      dispatch(setUser(res.data))
      dispatch(UIStopLoading())
    }).catch(err => console.log(err.response.data))
  }
}

export const setUser = data => {
  return {
    type: GET_USER,
    userData: data
  }
}

export const errorUser = data => {
  return {
    type: ERROR_USER,
    error: data
  }
}

export const authSignup = authData => {
  return dispatch => {
    dispatch(UIStartLoading())
    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/set-user',
      method: 'POST',
      withCredentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      },
      data: authData
    })
    .then(res => {
      dispatch(setUser(res.data))
      dispatch(UIStopLoading())
    }).catch(err => console.log(err.response.data))
  }
}



export const authUser = authData => {
  return dispatch => {
    dispatch(UIStartLoading())
    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/auth-user',
      method: 'POST',
      withCredentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      },
      data: authData
    }).then(res => {
      dispatch(modalClose())
      dispatch(setUser(res.data.user))

      if (window.matchMedia('(max-width: 1112px').matches){
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }else{
        document.querySelector('main').scrollTop = 0
      }
      
      dispatch(UIStopLoading())
    }).catch(err => {
      dispatch(errorUser(err.response.data.msg))
      dispatch(UIStopLoading())
    })
  }
}