import React from 'react'

// styles
import Classes from './Offline.css'

export default () => {
  return (
    <div className={Classes.Offline}></div>
  )
}
