import React, { Component, Fragment } from 'react'
import _ from 'lodash'

// components
import FormFields from '../UI/FormFields/FormFields'
import Button from '../UI/Button/Button'
import FormTitle from '../UI/FormTitle/FormTitle'
import Error from '../UI/Error/Error'

// utils
import validate from '../../util/validation'

// style
import Classes from './Pin.css'

// assets
import Strings from '../../assets/strings.json'

export default class Pin extends Component {
  state = {
    fields: {
      pin: {
        value: '',
        touched: false,
        error: false
      },
      newPin: {
        value: '',
        touched: true,
        error: false
      },
      retype: {
        value: '',
        touched: true,
        error: false
      }
    },
    
    change: false
  }

  pinChangeHandler = (pin, key) => {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        retype: {
          value: key === 'newPin' ? '' : prevState.fields.retype.value,
          touched: key === 'newPin'? false : prevState.fields.retype.touched,
          error: false
        },
        [key]: {
          value: pin,
          touched: true,
          error: key === 'retype' ? validate(pin, {
            required: true,
            equal: prevState.fields.newPin.value
          }) : validate(pin, {
            required: true,
            number: true,
            minStr: 4,
            maxStr: 4
          })
        }
      }
    }))
  }

  submitPin = e => {
    e.preventDefault()

    if (this.state.change) { 
      this.props.submitPin(this.state.fields.pin.value, this.state.fields.newPin.value)
    } else {
      this.props.submitPin(this.state.fields.pin.value)
    }
  }

  pinSwitch = () => {
    this.setState(prevState => ({ change: !prevState.change }))
  }

  render() {
    let dis = false
    
    if (this.state.change){
      _.each(this.state.fields, item => {
        dis = !item.touched || item.error
      })
    }else{
      dis = !this.state.fields.pin.touched || this.state.fields.pin.error
    }
    
    const newPin = (
      <div className={Classes.newPin}>
        <FormFields id="newPinField"
                    error={this.state.fields.newPin.error}
                    touched={this.state.fields.newPin.touched}
                    value={this.state.fields.newPin.value}
                    type="password"
                    name="newPin"
                    onChange={(e) => this.pinChangeHandler(e, 'newPin')}
                    title={Strings.newPin}
                    small={true}
        />
        <FormFields id="pinRetypeField"
                    error={this.state.fields.retype.error}
                    touched={this.state.fields.retype.touched}
                    value={this.state.fields.retype.value}
                    type="password"
                    name="retype"
                    onChange={(e) => this.pinChangeHandler(e, 'retype')}
                    title={Strings.retypePin}
                    small={true}
        />
      </div>
    )
    return (
      <Fragment>
        <FormTitle>{this.state.change ? Strings.changePin : this.props.formTitle}</FormTitle>
        <form onSubmit={this.submitPin} style={{position: 'relative'}}>
          <FormFields id="pinField"
                      error={this.state.fields.pin.error}
                      touched={this.state.fields.pin.touched}
                      value={this.state.fields.pin.value}
                      type="password"
                      name="pin"
                      onChange={(e) => this.pinChangeHandler(e, 'pin')}
                      title={this.state.change ? Strings.currentPin : Strings.dig4}
                      small={true}
          />
          {this.state.change ? newPin : null}
          <Button type="submit" disabled={dis} short={true}>{Strings.submit}</Button>
          {this.props.error ? <Error>{ Strings.pinError }</Error>: null}
        </form>
        {!this.state.change ? <div onClick={this.pinSwitch} className={Classes.ChangeLink}>{Strings.changePin}</div> : <div onClick={this.pinSwitch} className={Classes.ChangeLink}>{Strings.cancel}</div>}
      </Fragment>
    )
  }
}
