import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from "lodash"

// components
import Fieldset from '../UI/Fieldset/Fieldset'
import FormFields from '../UI/FormFields/FormFields'
import Button from '../UI/Button/Button'

// actions
import { getSettings, setSettings, modalOpen } from '../../store/actions/index'

// utils
import validate from '../../util/validation'

// styles
import Classes from './HomeCLUForm.css'

// assets
import Settings from '../../assets/settings.json'
import Strings from '../../assets/strings.json'

class HomeCLUForm extends Component {
  state = {
    fields: {},
    fieldsets: [],
    dis: true
  }

  componentDidMount = () => {
    this.props.onRef(this)
    if (window.matchMedia('(max-width: 1112px').matches){
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }else{
      document.querySelector('main').scrollTop = 0
    }
    
    const fields = this.settingsToState(Settings.homeCLUForm)

    const fieldsets = Settings.homeCLUForm.map(f => {
      let title = f.title
      if (fields.connectors && fields.connectors.value) {
        title = f.title.replace('"x"', fields.connectors.value.length)
      }else{
        title = f.title.replace('"x"', 0)
      }

      return {id: f.id, title: title}
    })

    let dis = false
    _.forEach(fields, field => {
      if ((field.validation && field.validation.required && !field.touched) || field.error) {
        dis = true
      }
    })

    if(_.isEmpty(fields.connectors.value)) {
      dis = true
    }

    this.setState({fields, fieldsets, dis})
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  settingsToState = data => {
    let fields = {}

    if(data.length>0){
      _.each(data, (fieldset, group) => {
        _.each(fieldset.fields, (items) => {
          fields[items.id] = {
            ...items,
            value: this.props.homeCLU[items.id] || items.default,
            touched: this.props.homeCLU[items.id] || items.default ? true : false,
            hide: false,
            group,
            dependents: {}
          }
        })
      })

      _.each(fields, (it, i) => {
        if ( it.only  ) {
          let check = true
          _.each(it.only, (only, k) => {
            fields[k].dependents[i] = {'changeVisible': true}

            if(only.indexOf(fields[k].value) !== -1) {
              check = check && true
            }else{
              check = check && false
            }
          })

          it.hide = !check
        }

        if ( it.options ) {
          _.each(it.options, (option, o) => {
            if (typeof option === 'object' && option.only) {
              let check = true
              _.each(option.only, (only, k) => {
                fields[k].dependents[i] = {'changeOptions': true}

                if(only.indexOf(fields[k].value) !== -1) {
                  check = check && true
                }else{
                  check = check && false
                }
              })

              fields[i].options[o]['show'] = check
            }
          })
        }

        if ( it.validation ) {
          _.each(it.validation, (validation, v) => {
            if ( v === 'lessOrEqual' ) {
              fields[validation].dependents[i] = {'lessOrEqual': i}
            }
          })
        }
      })
    }

    return fields
  }

  stateToSettings = () => {
    const values = {}
    _.each(this.state.fields, (field, key) => {
      if (key !== 'connectors') {
        values[key] = field.value
      }
    })

    return values
  }

  submitConf = e => {
    e.preventDefault()
    this.props.openModal(null, Strings.toSave, this.props.user.name ? 'reSignup' : 'signup')
  }

  mainOnChangeHandler = (e, id) => {
    this.setState(prevState => {
      const settings = {}
      let dis = false

      if(id === 'connectors'){
        prevState.fields.connectors.value = e
      }else{
        _.each(prevState.fields, (item, i) => {
          if (item.id === id) {
            const val = typeof e === 'object' ? e.value: e
            prevState.fields[i].value = i==='maxTotalChargeCurrent' ? (val?parseInt(val):''):val
            prevState.fields[i].touched = true
            const error = validate(val, this.state.fields[i].validation, this.state.fields)
            prevState.fields[i].error = error
  
            _.forEach(prevState.fields[i].dependents, (item, key) => {
              if (item.checked){
                if (prevState.fields[i].value === true) {
                  prevState.fields[key].hide = true
                  prevState.fields[key].touched = false
                }else{
                  prevState.fields[key].hide = false
                  prevState.fields[key].touched = true
                  prevState.fields[key].value = prevState.fields[key].default
                }
              }else if(item.changeOptions){
                const transformedOptions = this.changeOptions(val, prevState.fields[key].options, prevState.fields)
  
                prevState.fields[key].options = transformedOptions
                prevState.fields[key].touched = prevState.fields[key].default ? true : false
                prevState.fields[key].value = prevState.fields[key].default
              }else if(item.changeVisible){
                const transformedItem = this.changeVisible(val, prevState.fields[key], prevState.fields)

                prevState.fields[key] = transformedItem
                prevState.fields[key].touched = prevState.fields[key].default ? true : false
                if (transformedItem.hide) {
                  prevState.fields[key].value = prevState.fields[key].default
                }
              }else if(item.lessOrEqual){
                prevState.fields[item.lessOrEqual].error = validate(prevState.fields[item.lessOrEqual].value, prevState.fields[item.lessOrEqual].validation, prevState.fields)
              }
            })
          }
  
          if (i !== 'connectors') {
            settings[i] = prevState.fields[i].value
            if ((prevState.fields[i].validation && prevState.fields[i].validation.required && !prevState.fields[i].touched) || prevState.fields[i].error) {
              dis = true
            }
          }
          
        })
        this.props.setSettings(settings, true)
      }
      
      if(_.isEmpty(prevState.fields.connectors.value)) {
        dis = true
      }

      prevState.dis = dis

      return prevState
    })
  }

  componentDidUpdate() {
    if (!this.state.dis && _.isEmpty(this.props.homeCLU.connectors)){
      this.setState(prevState => ({
        ...prevState,
        dis: true, 
        fields: { 
          ...prevState.fields,
          connectors: {
            ...prevState.fields.connectors,
            value: null
          }
        }
      }))
    }
  }

  changeOptions = (val, options, fields) => {
    _.each(options, (option, o) => {
      if (typeof option === 'object' && option.only) {
        let check = true
        _.each(option.only, (only, k) => {

          if(only.indexOf(fields[k].value) !== -1) {
            check = check && true
          }else{
            check = check && false
          }
        })

        options[o]['show'] = check
      }
    })

    return options
  }

  changeVisible = (val, it, fields) => {
    let check = true
    _.each(it.only, (only, k) => {
      if(only.indexOf(fields[k].value) !== -1) {
        check = check && true
      }else{
        check = check && false
      }
    })

    it.hide = !check

    return it
  }

  render() {
    const fields = []

    _.forEach(this.state.fields, f => {
      if(fields[f.group]) {
        fields[f.group].push(f)
      }else{
        fields.push([f])
      }
    })

    return (
      <form onSubmit={this.submitConf} className={Classes.cluForm}>
        {this.state.fieldsets.map((fieldset, i) => {
          let last = fields[i].length
          let curr = 0
          return (
            <Fieldset data={fieldset} num={i} key={i}>
              {_.map(fields[i], (field, k) => {
                curr++
                return !field.hide ? 
                  <FormFields {...field}
                              position='inline'
                              onChange={this.mainOnChangeHandler}
                              key={field.id}
                              name={field.id}
                              value={this.state.fields[field.id].value}
                              last={last === curr}
                  /> : null
              })}
            </Fieldset>
          )
        })}
        <div className={Classes.Buttons}>
          <Button disabled={this.state.dis}>{Strings.saveConf}</Button>
        </div>
      </form>
    )
  }
}

const mapStateToPorps = state => {
  return {
    homeCLU: state.homeCLU.settings || {},
    loading: state.ui.loading,
    user: state.user.userData
  }
}

const mapDispatchtoProps = dispatch => {
  return {
    getSettings: () => dispatch(getSettings()),
    setSettings: (data, undo) => dispatch(setSettings(data, undo)),
    openModal: (body, title, tyep) => dispatch(modalOpen(body, title, tyep))
  }
}

export default connect(mapStateToPorps, mapDispatchtoProps)(HomeCLUForm)