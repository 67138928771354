import React, { Component } from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom'
import { Provider } from "react-redux"

// store
import configureStore from './store/configureStore'

// importing route specific containers
import StatusScreen from './containers/StatusScreen/StatusScreen'
import HomeCLU from './containers/HomeCLU/HomeCLU'

const store = configureStore()

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Switch>
              <Route path="/" exact component={StatusScreen} />
              <Route path="/homeCLU" component={HomeCLU} />
            </Switch>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App
