import { SAVE_STATUS } from './actionTypes'
import {UIStartLoading, UIStopLoading} from './ui'
import _ from 'lodash'

// strings
import Strings from '../../assets/strings.json'
import Axios from 'axios'

export const saveStatus = (data) => {
  return {
    type: SAVE_STATUS,
    data
  }
}

export const getStatus = () => dispatch => {
  dispatch(UIStartLoading())
  Axios({
    url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/get-status',
    method: 'GET'
  }).then(res => {
    const d = convertData(res.data)
    dispatch(saveStatus(d))
    dispatch(UIStopLoading())
  })
}

const convertData = data => _.map(data, (item) => {
  const data = {
    name: Strings[item.id] || '',
    value: item.id === 'CLUType' ? Strings[item.value] : item.value
  }

  if (item.id === 'network') {
    data.settings = item
    if (item.value && item.value.network !== 'Offline') {
      data.value = `${item.value.network} ${item.value.ip}`
      if (item.value.network === 'Wi-Fi' || item.value.network === 'SIM 4G'){
        if (0 <= item.value.RSSI){
          data.widget = {
            type: 'signal',
            amount: item.value.RSSI
          }
        } else {
          data.widget = {
            type: 'offline'
          }
        }

      } else {
        data.widget = {
          type: 'online'
        }
      }
    }else{
      data.value = Strings.offline
      data.widget = {
        type: 'offline'
      }
    }
  }

  return data
})
