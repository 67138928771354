import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

// components
import Button from '../UI/Button/Button'
import FormFields from '../UI/FormFields/FormFields'
import Loader from '../UI/Loader/Loader'
import Error from '../UI/Error/Error'
import Close from '../UI/Close/Close'

// actions
import { modalClose, authUser, authSignup, updateSettings } from '../../store/actions/index'

// utility
import validate from '../../util/validation'

// styles
import Classes from './UserForm.css'

// assets
import Strings from '../../assets/strings.json'

export class UserForm extends Component {
  state = {
    fields: {}
  }

  componentDidMount = () => {
    let fields = {}
    if(this.props.modal.content === 'signup' || this.props.modal.content === 'reSignup') {
      fields = [
        {
          'id': 'name',
          'title': Strings.name,
          'type': 'text',
          'value': this.props.user.userData.name || '',
          'show': true,
          'touched': this.props.modal.content === 'reSignup' ? true : false,
          'error': false
        },
        {
          'id': 'company',
          'title': Strings.company,
          'type': 'text',
          'value': this.props.user.userData.company || '',
          'show': true,
          'touched': this.props.modal.content === 'reSignup' ? true : false,
          'error': false
        },
        {
          'id': 'password',
          'title': Strings.password,
          'type': 'password',
          'value': '',
          'show': this.props.modal.content === 'reSignup' ? false : true,
          'touched': false,
          'error': false
        },
        {
          'id': 'rePassword',
          'title': Strings.rePassword,
          'type': 'password',
          'value': '',
          'show': this.props.modal.content === 'reSignup' ? false : true,
          'touched': false,
          'error': false
        }
      ]
    }else if (this.props.modal.content === 'login') {
      fields = [
        {
          'id': 'password',
          'title': Strings.password,
          'type': 'password',
          'value': '',
          'touched': false,
          'show': true,
          'error': false
        }
      ]
    }

    this.setState({fields})
  }
  
  onChangeHandler = (value, key) => {
    if (this.props.modal.content === 'login') {
      this.setState(prevState => {
        const fid = _.findIndex(prevState.fields, {id: key})
        const updatedField = {
          ...prevState.fields[fid],
          value,
          touched: true,
          error: validate(value, {required: true})
        }
        
        return prevState.fields[fid] = updatedField
      })
    } else if (this.props.modal.content === 'signup' || this.props.modal.content === 'reSignup') {
      this.setState(prevState => {
        const fid = _.findIndex(prevState.fields, {id: key})
        const pid = _.findIndex(prevState.fields, {id: 'password'})
        const rpid = _.findIndex(prevState.fields, {id: 'rePassword'})
        let s = value !== this.props.user.userData[key]
        
        if (key === 'rePassword') {
          const updatedField = {
            ...prevState.fields[fid],
            value,
            touched: true,
            error: value !== prevState.fields[pid].value ? Strings.mustMatch : false
          }

          return prevState.fields[fid] = updatedField
        }else{
          const updatedPField = {
            ...prevState.fields[pid],
            value: !s ? '' : prevState.fields[pid].value,
            show: s,
            touched: !s
          }
          const updatedRPField = {
            ...prevState.fields[rpid],
            show: s,
            value: !s ? '' : prevState.fields[rpid].value,
            touched: !s
          }

          const updatedField = {
            ...prevState.fields[fid],
            value,
            touched: true,
            error: validate(value, {required: true})
          }
          
          prevState.fields[pid] = updatedPField
          prevState.fields[rpid] = updatedRPField
          prevState.fields[fid] = updatedField

          return prevState
        }
      })
    }
  }

  submitFormHandler = e => {
    e.preventDefault()
    
    if (this.props.modal.content === 'login') {
      const field = _.find(this.state.fields, {id: 'password'})
      this.props.authUser({password: field.value})
    } else if (this.props.modal.content === 'signup' || this.props.modal.content === 'reSignup') {
      const userData = {}
      _.each(this.state.fields, item => {
        userData[item.id] = item.value
      })
      this.props.setHomeCLU(this.props.homeCLU.settings, userData)
    }
  }

  render() {
    let dis = false

    _.each(this.state.fields, (field) => {
      if (field.show){
        if (field.error || !field.touched) {
          dis = true
          return false
        }
      }
    })
    let content = (
      <div className={Classes.UserForm}>
        <Close click={() => this.props.modal.content === 'login' ? this.props.history.push('/'): this.props.closeModal()} />
        {this.props.modal.title ? <h3>{this.props.modal.title}</h3>: null}
        {this.props.modal.body ? <div className={Classes.Body}>{this.props.modal.body}</div> : null}
        <form onSubmit={this.submitFormHandler} style={{position: 'relative'}}>
          {_.map(this.state.fields, field => field.show ? <FormFields {...field} onChange={this.onChangeHandler} key={field.id} name={field.id} value={field.value} /> : null)}
          <Button disabled={dis} short={this.props.modal.content === 'login'}>{this.props.modal.content === 'login' ? Strings.submit : Strings.saveConf}</Button>
          {this.props.homeCLU.error || this.props.user.error ? <Error>{this.props.homeCLU.error || this.props.user.error}</Error>: null}
        </form>
      </div>
    )
    
    if (this.props.loading) {
      content = <Loader />
    }
    return content
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  modal: state.modal,
  loading: state.ui.loading,
  homeCLU: state.homeCLU
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(modalClose()),
  authUser: password => dispatch(authUser(password)),
  authSignup: data => dispatch(authSignup(data)),
  setHomeCLU: (data, user) => dispatch(updateSettings(data, user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserForm))
