import React from 'react'

// styles
import Classes from './Error.css'

export default props => {
  return (
    <div className={[Classes.ErrorMsg, props.inline ? Classes.Inline : null].join(' ')}>
      {props.children}
    </div>
  )
}
