import React from 'react'

// styles
import Classes from './Fieldset.css'

export default (props) => {
  return (
    <fieldset className={Classes.Fieldset}>
      <label>{props.data.title}</label>
      {props.children}
    </fieldset>
  )
}
