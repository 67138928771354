import React from 'react'

// components
import StatusListItem from './StatusListItem/StatusListItem'

// styles
import Classes from './StatusList.css'

export default (props) => {
  let change = false
  let adTittle = false
  return (
    <div className={Classes.StatusList}>
      {props.items.map((item, i) => {
        adTittle = item.name === "Time"
        if(item.name === "Time"){
          change = true
        }
        return window.matchMedia('(max-width: 480px)').matches && item.name === 'CLU network connection' ? null : <StatusListItem data={item} key={i} change={change} adTittle={adTittle} />
      })}
    </div>
  )
}
