import React from 'react'

// styles
import Classes from './Signal.css'

// assets
import WiFi from '../../assets/wifil.svg'

export default (props) => {
  const dots = []
  for (let i = 0; i < 4; i++) {
    const dotColor = props.signal === 0 && i === 0 ? Classes.DotOrange : (props.signal-i > 0 ? Classes.DotGreen : Classes.DotGrey)
    dots.push(<div key={i} className={[Classes.Dot, dotColor].join(' ')} />)     
  }
  return (
    <div className={[Classes.Widget, Classes.Signal].join(' ')}>
      <img src={WiFi} className={Classes.WidgetIcon} alt="WiFi" />
      <div className={Classes.SignalBar}>
        {dots}
      </div>
    </div>
  )
}
