import { MODAL_CLOSE, MODAL_OPEN } from './actionTypes'

export const modalOpen = (body, title, content) => {
  return {
    type: MODAL_OPEN,
    content,
    body,
    title
  }
}

export const modalClose = () => {
  return {
    type: MODAL_CLOSE
  }
}