import React from 'react'

// styles
import Classes from './Modal.css'

export default (props) => {
  let modalStyles = null
  if(props.height) {
    modalStyles = {minHeight: `${props.height}px`}
  }

  return (
    <div className={Classes.ModalBack} onClick={props.backHandler}>
      <div className={Classes.ModalMain} style={modalStyles}>
        <div className={Classes.ModalMainInner}>
          {props.children}
        </div>
      </div>
    </div>
  )
}
