import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from "lodash"
import {
	withRouter
} from 'react-router-dom';

// components
import Layout from '../../components/Layout/Layout'
import Button from '../../components/UI/Button/Button'
import HomeCLUForm from '../../components/HomeCLUForm/HomeCLUForm'
import Loader from '../../components/UI/Loader/Loader'
import ChargePointForm from '../../components/ChargePointForm/ChargePointForm'
import Modal from '../../components/Modal/Modal'
import UserForm from '../../components/UserForm/UserForm'
import Desc from '../../components/UI/Desc/Desc'
import BackBox from '../../components/BackBox/BackBox'
import YesNoBox from '../../components/YesNoBox/YesNoBox'

// actions
import { getSettings, modalOpen, modalClose, cancelAddingCP } from '../../store/actions/index'

// styles
//mport Classes from './HomeCLU.css'

// assets
import Strings from '../../assets/strings.json'

class homeCLU extends Component {
  state = {
    authorise: false,
    back: false,
    undo: false,
    config: {}
  }

  componentDidMount = () => {
    this.props.getSettings()
    this.props.openModal(Strings.warning, Strings.CLUConf, 'login')
  }

  backHandler = () => {
    if (this.props.homeCLU.cpForm !== false) {
      this.props.openModal(Strings.withoutCP, Strings.saveCPTitle, 'cpBack')
    } else {
      this.props.openModal(Strings.without, Strings.saveConfTitle, 'back')
    }
  }

  undoHandler = () => {
    this.props.getSettings()
    this.props.closeModal()
  }

  render() {
    const backButton = <Button onClick={this.backHandler} back={true}>{Strings.back}</Button>
    const undoButton = <Button transparent={true} grey={true} onClick={() => this.props.openModal(Strings.undoBody, Strings.undoTitle, 'undo')} disabled={!this.props.homeCLU.undo}>{Strings.undo}</Button>

    let content = <HomeCLUForm onRef={ref => this.cluForm = ref} />
    let close = null

    if (this.props.loading){
      content = <Loader />
    }else if (this.props.homeCLU.cpForm !== false){
      content = <ChargePointForm onRef={ref => this.cpForm = ref} />
    }

    let modalContent = <UserForm />
    if (this.props.modal.content === 'desc') {
      modalContent = <Desc />
    }else if (this.props.modal.content === 'back') {
      close = !_.isEmpty(this.cluForm) && !this.cluForm.state.dis ? (e) => {this.cluForm.submitConf(e)} : null
      modalContent = <BackBox close={close} backTo={() => this.props.history.push('/')} />
    }else if (this.props.modal.content === 'cpBack') {
      close = !this.cpForm.state.dis ? (e) => {this.cpForm.submitConf(e);this.props.closeModal()} : null
      modalContent = <BackBox close={close} backTo={this.props.cancelCP} />
    }else if (this.props.modal.content === 'undo') {
      modalContent = <YesNoBox yes={this.undoHandler} />
    }else if (this.props.modal.content === 'del') {
      modalContent = <YesNoBox />
    }
    
    return (
      <Fragment>
        <Layout top={backButton} bottom={this.props.homeCLU.cpForm !== false ? null : undoButton} title={this.props.homeCLU.cpForm !== false ? Strings.cpConf : Strings.homeConf}>
          {content}
        </Layout>
        {this.props.modal.show ? <Modal>{modalContent}</Modal> : null}
      </Fragment>
    )
  }
}

const mapStateToPorps = state => {
  return {
    homeCLU: state.homeCLU,
    loading: state.ui.loading,
    user: state.user.userData,
    modal: state.modal
  }
}

const mapDispatchtoProps = dispatch => {
  return {
    getSettings: () => dispatch(getSettings()),
    openModal: (data, title, type) => dispatch(modalOpen(data, title, type)),
    closeModal: () => dispatch(modalClose()),
    cancelCP: () => dispatch(cancelAddingCP())
  }
}

export default connect(mapStateToPorps, mapDispatchtoProps)(withRouter(homeCLU))
