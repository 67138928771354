import React, { Component } from 'react'
import {RadioGroup, Radio} from 'react-radio-group'
import _ from 'lodash'

// styles
import Classes from './RadioGroup.css'

export default class RadioGroupElement extends Component {
  state = {
    val: null
  }

  componentDidMount = () => {
    if (this.props.value) {
      this.setState({val: this.props.value})
    }
  }
  

  radioChangeHandler = val => {
    this.setState({val})

    this.props.onChange(val)
  }

  render() {
    return (
      <RadioGroup onChange={this.radioChangeHandler} name={this.props.name} selectedValue={this.state.val} className={[Classes.RadioGroup, Classes[this.props.id]].join(' ')}>
        {_.map(this.props.options, (label, val) => (
          <label key={val} className={[Classes.RadioButton, Classes[val], this.state.val === val ? Classes.Checked : null].join(' ')}>
            <div className={Classes.RadioCircle}></div>
            <Radio value={val} />{label}
          </label>
        ))}
      </RadioGroup>
    )
  }
}
