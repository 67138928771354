import {CANCEL_ADDING_CHARGE_POINT, GET_HOMECLU_SETTINGS, ADD_CHARGE_POINT, DELETE_CHARGE_POINT, START_ADDING_CHARGE_POINT, UPDATE_CHARGE_POINT, ERROR_HOMECLU_SETTINGS} from '../actions/actionTypes'

const initialState = {
  settings: {},
  cpForm: false,
  error: false,
  undo: false
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case ERROR_HOMECLU_SETTINGS:
      return {
        ...state,
        error: action.error
      }
    case GET_HOMECLU_SETTINGS: 
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings
        },
        undo: action.undo
      }
    case ADD_CHARGE_POINT: 
      if (state.settings.connectors){
        if (action.cpForm !== true) {
          const cps = [...state.settings.connectors]
          cps[action.cpForm] = action.chargePoint
          return {
            settings: {
              ...state.settings,
              connectors: cps
            },
            cpForm: false,
            undo: true
          }
        }else{
          return {
            settings: {
              ...state.settings,
              connectors: [
                ...state.settings.connectors,
                action.chargePoint
              ]
            },
            cpForm: false,
            undo: true
          }
        }
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          connectors: [ action.chargePoint ]
        },
        cpForm: false,
        undo: true
      }
      
    case DELETE_CHARGE_POINT: 
      const connectors = [
        ...state.settings.connectors
      ]

      connectors.splice(-1)
      return {
        ...state,
        settings: {
          ...state.settings,
          connectors
        }
      }
    case UPDATE_CHARGE_POINT:
      const newCPs = state.settings.connectors.map((item, i) => {
        return {
          ...item,
          priority: i === action.settings ? (state.settings.connectors[i].priority ? false : true) : item.priority
        }
      })
      return {
        ...state,
        settings: {
          ...state.settings,
          connectors: newCPs
        },
        undo: true
      }
    case START_ADDING_CHARGE_POINT: 
      return {
        ...state,
        cpForm: action.cpForm
      }
    case CANCEL_ADDING_CHARGE_POINT:
      return {
        ...state,
        cpForm: false
      }
    default: 
      return state
  }
}

export default reducer