import {SAVE_STATUS} from '../actions/actionTypes'

const reducer = (state = [], action) => {
  switch (action.type) {
    case SAVE_STATUS:
    return action.data
    default:
      return state
  }
}

export default reducer