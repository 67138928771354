import { UI_START_LOADING, UI_STOP_LOADING } from './actionTypes'

export const UIStartLoading = () => {
  return {
    type: UI_START_LOADING
  }
}

export const UIStopLoading = () => {
  return {
    type: UI_STOP_LOADING
  }
}