import React from 'react'

// styles
import Classes from './Title.css'

export default (props) => {
  const titleParts = props.children.split(' ')

  return (
    <h1 className={Classes.MainTitle}>
      <span className={Classes.Red}>{titleParts.shift()}</span><br/>
      {titleParts.join(' ')}<br />
      {props.subtitle && <span className={Classes.Smaller}>{props.subtitle}</span>}
    </h1>
  )
}
