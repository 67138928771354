import React from 'react'

// styles
import Classes from './Close.css'

// assets
import {ReactComponent as Close} from '../../../assets/false.svg'

export default props => {
  return (
    <div className={Classes.Close} onClick={props.click}>
      <Close />
    </div>
  )
}
