import { MODAL_CLOSE, MODAL_OPEN } from '../actions/actionTypes'

const initialState = {
  show: false,
  content: null,
  body: null,
  title: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
    return {
      ...state,
      show: true,
      content: action.content,
      body: action.body,
      title: action.title
    }
    case MODAL_CLOSE:
      return {
        ...state,
        show: false,
        content: null,
        body: null,
        title: null
      }
    default:
      return state
  }
}

export default reducer