import React from 'react'

// styles
import Classes from './Checkbox.css'

// assets
import {ReactComponent as OK} from '../../../../assets/ok.svg'

export default props => {
  return (
    <label className={[Classes.Checkbox, props.off ? Classes.Disabled : null].join(' ')}>
      {props.value ? <OK /> : null}
      <input checked={props.value} onChange={(e) => {
        props.onChange(e.target.checked)
      }} name={props.name} type="checkbox" />
    </label>
  )
}
