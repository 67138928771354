import React from 'react'

// components
import Title from '../UI/Title/Title'

//styles
import Classes from './Layout.css'

export default (props) => {
  return (
    <div className={Classes.Wrapper}>
      <div className={Classes.Left}>
        <div className={Classes.LeftTop}>
          {props.top}
        </div>
        <div className={Classes.LeftTitle}>
          <Title subtitle={props.subtitle}>{props.title}</Title>
        </div>
        {props.middle? <div className={Classes.LeftMiddle}>{props.middle}</div> : null}
        {window.matchMedia('(max-width: 480px)').matches ? null : <div className={Classes.LeftBottom}>
          {props.bottom}
        </div>}
      </div>
      <main className={Classes.Main}>
        {props.children}
      </main>
      {window.matchMedia('(max-width: 480px)').matches ? <div className={Classes.Bottom}>
        {props.bottom}
      </div>:null}
    </div>
  )
}
