import React from 'react'

// styles
import Classes from './Button.css'

// assets
import {ReactComponent as BackIcon} from '../../../assets/arrow.svg'

export default (props) => {
  const cs = props.cs || []
  return (
    <button onClick={props.onClick} disabled={props.disabled} className={[
        ...cs,
        props.className,
        Classes.Button, props.short ? Classes.Short : null,
        props.transparent ? Classes.Transparent : null,
        props.grey ? Classes.Grey : null,
        props.back ? Classes.Back : null
    ].join(' ')}>
      {props.back ? <BackIcon />:null}
      {props.icon}
      {props.children}
    </button>
  )
}
