import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'


// components
import TextField from './TextField/TextField'
import RadioGroup from './RadioGroup/RadioGroup'
import Select from './Select/Select'
import Checkbox from './Checkbox/Checkbox'
import ChargePoint from './ChargePoint/ChargePoint'
import ReadOnly from './ReadOnly/ReadOnly'
import Error from '../Error/Error'

// actions
import { modalOpen } from '../../../store/actions'

// styles
import Classes from './FormFields.css'

// assets
import Strings from '../../../assets/strings.json'
import DescButton from '../DescButton/DescButton';

class FormField extends Component {

  render() {
    let field = null

    switch (this.props.type) {
      case 'text':
        field = <TextField onChange={(e) => this.props.onChange(e.target.value, this.props.id)} id={this.props.id} type="text" name={this.props.name} value={this.props.value} />
        break
      case 'password':
        field = <TextField onChange={(e) => this.props.onChange(e.target.value, this.props.id)} id={this.props.id} type="password" name={this.props.name} value={this.props.value} />
        break
      case 'radios':
        field = <RadioGroup onChange={val => this.props.onChange(val, this.props.id)} id={this.props.id} name={this.props.name} options={this.props.options} value={this.props.value} />
        break
      case 'select':
        const options = _.map(this.props.options, (label, value) => { return { label, value } })
        field = <Select onChange={val => this.props.onChange(val, this.props.id)} name={this.props.name} options={options} value={this.props.value} />
        break
      case 'checkbox':
        field = <Checkbox onChange={val => this.props.onChange(val, this.props.id)} name={this.props.name} type="checkbox" value={this.props.value} />
        break
      case 'chargePoint':
        field = <ChargePoint onChange={val => this.props.onChange(val, this.props.id)} settings={this.props.widgetSettings} values={this.props.value} value={this.props.value} desc={this.props.desc} title={this.props.title}/>
        break
      case 'readOnly':
        field = <ReadOnly value={this.props.value} />
        break
      default:
        break
    }

    return (
      <div className={[Classes.FormField, `form-field-${this.props.type}`, Classes[this.props.id], (this.props.position === 'inline' ? Classes.Inline : Classes.Above), this.props.last ? Classes.Last : null].join(' ')}>
        <div className={Classes.FormFieldInner}>
          {this.props.title && this.props.type !== 'chargePoint' ? <h3 className={Classes.FieldTitle}>{this.props.title}</h3>: null}
          <div className={[Classes.FieldInput, this.props.suffix ? Classes.WithSuffix: null].join(' ')}>
            {field}
            {this.props.suffix ? <div className={Classes.Suffix}>{this.props.suffix}</div> : null}
          </div>
          {this.props.desc && this.props.type !== 'chargePoint' ? <DescButton click={() => this.props.openModal(this.props.desc, `${this.props.title} ${Strings.desc}`, 'desc')} descPos={this.props.descPos} /> : null}
        </div>
        {this.props.error && this.props.touched ? <Error inline={this.props.position === 'inline' ? true : false}>{this.props.error}</Error> : null}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: (body, title, content) => dispatch(modalOpen(body, title, content))
})

export default connect(null, mapDispatchToProps)(FormField)
