import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

// components
import FormFields from '../UI/FormFields/FormFields'
import Button from '../UI/Button/Button'
import FormTitle from '../UI/FormTitle/FormTitle'

// actions
import { addCP } from '../../store/actions'

// utils
import validate from '../../util/validation'

// styles
import Classes from './ChargePointForm.css'

// assets
import Settings from '../../assets/settings.json'
import Strings from '../../assets/strings.json'

class ChargePointForm extends Component {
  state = {
    connectorFields: [],
    dis: true
  }

  componentDidMount = () => {
    this.props.onRef(this)

    if (window.matchMedia('(max-width: 1112px').matches){
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }else{
      document.querySelector('main').scrollTop = 0
    }

    let connectorFields = null
    document.querySelector('main').scrollTo = 0

    _.each(Settings.homeCLUForm, item => {
      _.each(item.fields, it => {
        if (it.id === 'connectors') {
          connectorFields = it.widgetSettings.fields
        }
      })
    })

    const fields = {}
    let dis = false
    connectorFields.forEach(el => {
      let val = el.id === 'address' ? this.props.address: el.default
      if (this.props.cpkey !== true && this.props.homeCLU.connectors[this.props.cpkey]) {
        val = this.props.homeCLU.connectors[this.props.cpkey][el.id]
      }
      if (el.id !== 'priority') {
        fields[el.id] = ({
          ...el,
          value: val,
          hide: true,
          error: false,
          touched: ((el.validation && el.validation.required) && !val ? false : true),
          dependents: []
        })
        if (!fields[el.id].touched) {
          dis = true
        }
      }
    })

    _.each(fields, (it, i) => {
      if ( it.condition ) {
        fields[i].hide = false
        fields[i].touched = true
        _.each(it.condition, (condition, k) => {
          fields[condition].dependents.push({
            dependant: i,
            [k]: true
          })
        })
      }
    })

    this.setState({connectorFields: fields, dis})
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  onChangeHandler = (e, id) => {
    this.setState(prevState => {
      let dis = false
      _.each(prevState.connectorFields, (item, i) => {
        if (item.id === id) {
          const val = typeof e === 'object' ? e.value: e
          prevState.connectorFields[i].value = i === 'maxCurrent' ? (val?parseInt(val):'') : val
          prevState.connectorFields[i].touched = true
          const error = validate(val, this.state.connectorFields[i].validation, this.state.connectorFields)
          prevState.connectorFields[i].error = error

          prevState.connectorFields[i].dependents.forEach(item => {
            if (item.checked && prevState.connectorFields[i].value === true) {
              prevState.connectorFields[item.dependant].hide = true
              prevState.connectorFields[item.dependant].touched = false
            }else{
              prevState.connectorFields[item.dependant].hide = false
              prevState.connectorFields[item.dependant].touched = true
              prevState.connectorFields[item.dependant].value = ''
            }
          })
        }
        if (prevState.connectorFields[i].error || !prevState.connectorFields[i].touched) {
          dis = true
        }
      })

      prevState.dis = dis

      return prevState
    })
  }

  submitConf = e => {
    e.preventDefault()

    const chargePoint = {}
    _.each(this.state.connectorFields, (item, key) => {
      chargePoint[key] = item.value
    })

    chargePoint['priority'] = false
    this.props.addCP(chargePoint, this.props.cpkey)
  }

  render() {
    const last = parseInt(_.size(this.state.connectorFields))
    let curr = 0

    return (
      <form onSubmit={this.submitConf} className={Classes.cluForm}>
        <FormTitle>{Strings.cp}</FormTitle>
        {_.map(this.state.connectorFields, (field, i) => {
          curr++
          return field.hide ? <FormFields 
                                      {...field}
                                      position='inline' 
                                      onChange={this.onChangeHandler}
                                      key={field.id} 
                                      name={field.id}
                                      last={curr === last}
                                      value={this.state.connectorFields[i].value} /> : null
        
        })}
        <div className={Classes.Buttons}>
          <Button disabled={this.state.dis}>{Strings.saveConf}</Button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    homeCLU: state.homeCLU.settings,
    cpkey: state.homeCLU.cpForm,
    address: state.homeCLU.settings && state.homeCLU.settings.connectors ? state.homeCLU.settings.connectors.length+1 : 1
  }
}

const mapDispatchtoProps = dispatch => {
  return {
    addCP: (cp, key) => dispatch(addCP(cp, key)) 
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(ChargePointForm)
