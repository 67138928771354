import React, { Component } from 'react'
import { connect } from 'react-redux'

// components
import Button from '../UI/Button/Button'

// actions
import { modalClose } from '../../store/actions'

// styles
import Classes from './BackBox.css'

// assets
import Strings from '../../assets/strings.json'


class BackBox extends Component {
  save = () => {
    this.props.openModal(null, Strings.toSave, this.props.user.name ? 'reSignup' : 'signup')
  }

  render() {
    return (
      <div className={Classes.BackBox}>
        <h3 className={Classes.Title}>{this.props.modal.title}</h3>
        <div className={Classes.Body}>{this.props.modal.body}</div>
        <Button onClick={this.props.close || this.props.closeModal}>{Strings.saveConf}</Button>
        <div onClick={this.props.backTo} className={Classes.BackLink}>{Strings.goBack}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.modal
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(modalClose()) 
})

export default connect(mapStateToProps, mapDispatchToProps)(BackBox)