import React from 'react'
import Loader from 'react-loader-spinner'

// styles
import Classes from './Loader.css'

export default () => {
  return (<div className={Classes.Loading}>
    <Loader type="TailSpin" width={64} height={64} color={'#E01E1D'} />
  </div>)
}
