import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import DescButton from '../../DescButton/DescButton'

// components
import Checkbox from '../Checkbox/Checkbox'
import Button from '../../Button/Button'

// actions
import { startAddingCP, updateCP, modalOpen } from '../../../../store/actions'

// styles
import Classes from './ChargePoint.css'

// assets
import Settings from '../../../../assets/settings.json'
import {ReactComponent as False} from '../../../../assets/false.svg'
import {ReactComponent as Ok} from '../../../../assets/ok.svg'
import Strings from '../../../../assets/strings.json'

class ChargePoint extends Component {
  checkBoxChangeHandler = (k) => {
    this.props.updateCP(k)
  }

  addChargePoint = e => {
    e.preventDefault()
    this.props.startCP()
  }

  render() {
    let table = null
    
    if (!_.isEmpty(this.props.homeCLU)){
      const order = []
      const headerCells = []

      let iii = 0
      
      _.each(this.props.settings.fields, item => {
        if (window.matchMedia('(max-width: 480px)').matches) {
          if (iii === 0) {
            order.push({id: item.id, type: item.type})
            headerCells.push(<div className={Classes.HeaderCell} key={item.id + '-head'}>{item.headerTitle}</div>)
            order.push({id: 'priority', type: item.type})
            const p = this.props.settings.fields.filter(it => it.id === 'priority')
            headerCells.push(<div className={Classes.HeaderCell} key={'priority-head'}>{p[0].headerTitle}</div>)
          } else if (item.id !== 'priority') {
            order.push({id: item.id, type: item.type})
            headerCells.push(<div className={Classes.HeaderCell} key={item.id + '-head'}>{item.headerTitle}</div>)
          }
        }else{
          order.push({id: item.id, type: item.type})
          headerCells.push(<div className={Classes.HeaderCell} key={item.id + '-head'}>{item.headerTitle}</div>)
        }

        iii++
      })
      
      const header = <div className={[Classes.HeaderRow, Classes.Row].join(' ')} key="headerRow">{headerCells}</div>
      
      const rows = _.map(this.props.homeCLU.connectors, (row, k) => {
        return (
          <div key={k + '-row'} className={Classes.Row}>
            { order.map((it) => {
              let content = `${row[it.id]}${it.id === 'maxCurrent' ? 'A' : ''}`

              if (it.id === 'priority') {
                content = <Checkbox name={`checkbox-${k}`} onChange={() => this.checkBoxChangeHandler(k)} value={this.props.homeCLU.connectors[k].priority} />
              }else if (it.type === 'checkbox') {
                content = row[it.id] ? <Ok className={[Classes.OkIcon, Classes.Icon].join(' ')} /> : <False className={[Classes.FalseIcon, Classes.Icon].join(' ')} />
              }else if ( it.id === 'rfidGroup' ) {
                content = row[it.id] === 'None' || row[it.id] === '' ? <span className={Classes.None}>{Strings.none}</span> : row[it.id]
              }
              return (
                <div key={it.id + '-cell'} className={Classes.Cell} onClick={() => {
                  if (it.id !== 'priority'){
                    this.props.startCP(k)
                  }
                }}>{content}</div>
              )
            })}
          </div>
        )
      })

      table = [header, rows]
    }
    let max = 6
    _.each(Settings.homeCLUForm, it => {
      if (it.id === 'connectors'){
        _.each(it.fields, itt => {
          if (itt.id === 'connectors') {
            max = itt.validation.max
          }

          return false
        })
        return false
      }
    })

    return (
      <div className={Classes.ChargePoints}>
        {window.matchMedia('(max-width: 480px)').matches ? <div className={Classes.SwipeInfo}>{Strings.swipeInfo}</div> : null}
        <div className={Classes.ChargePointsInner}>
          <div className={Classes.ChargePointTable}>
            {!_.isEmpty(this.props.homeCLU.connectors) ? table : null}
          </div>
        </div>
        <div className={Classes.Buttons}>
          <Button cs={[Classes.CPBUtton]} transparent={true} onClick={this.addChargePoint} disabled={this.props.homeCLU.connectors && this.props.homeCLU.connectors.length === max}>{this.props.settings.buttons.add}</Button>
          <Button cs={[Classes.CPBUtton]} transparent={true} onClick={(e) => {e.preventDefault(); this.props.openModal(Strings.delBody, Strings.delTitle, 'del')}} disabled={!this.props.homeCLU.connectors || this.props.homeCLU.connectors.length === 0}>{this.props.settings.buttons.delete}</Button>
          <DescButton click={() => this.props.openModal(this.props.desc, `${this.props.title} ${Strings.desc}`, 'desc')} descPos='inline' mobile={true} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    homeCLU: state.homeCLU.settings
  } 
}

const mapDispatchtoProps = dispatch => {
  return {
    startCP: (key = true) => dispatch(startAddingCP(key)),
    updateCP: data => dispatch(updateCP(data)),
    openModal: (body, title, content) => dispatch(modalOpen(body, title, content))
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(ChargePoint)