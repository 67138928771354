import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Axios from 'axios'

// components
import Modal from '../../components/Modal/Modal'
import Pin from '../../components/Pin/Pin'
import ChangePin from '../../components/ChangePin/ChangePin'
import WANForm from '../../components/WANForm/WANForm'
import NetForm from '../../components/NetForm/NetForm'
import Loader from '../../components/UI/Loader/Loader'
import Close from '../../components/UI/Close/Close'
import Desc from '../../components/UI/Desc/Desc'

// styles
//import Classes from './WANScreen.css'

// assets
import Strings from '../../assets/strings.json'

class WANScreen extends Component {
  state = {
    access: false,
    wan: false,
    firstTime: false,
    accessError: false,
    loading: false,
    toStatus: false,
    saved: false,
    networkSettings: {}
  }

  submitPin = (pin, newPin = null) => {
    this.setState({loading: true})

    if (newPin) {
      this.sendPin(pin, (data) => {
        if (data.firstTime || data.success) {
          this.submitChangePin(newPin)
        }else{
          this.setState({accessError: true, loading: false})
        }
      })
    }else{
      this.sendPin(pin, (data) => {
        if (data.firstTime) {
          this.setState({firstTime: true, accessError: false, loading: false})
        }else if(data.success) {
          this.setState({access: true, accessError: false, loading: false})
        }else{
          this.setState({accessError: true, loading: false})
        }
      })
    }
  }


  sendPin = (pin, callback) => {
    Axios({
      url:(process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/check-pin',
      method: 'POST',
      withCredentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      },
      data: {pin: pin.toString()}
    })
    .then(res => callback(res.data))
    .catch(err => callback(err.response.data))
  }

  submitChangePin = pin => {
    this.setState({loading: true})
    this.sendChangePin(pin, (data) => {
      if(data.success) {
        this.setState({access: true, firstTime: false, accessError: false, loading: false})
      }else{
        this.setState({accessError: true, loading: false})
      }
    })
  }

  sendChangePin = (pin, callback) => {
    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/change-pin',
      method: 'POST',
      withCredentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      },
      data: {pin: pin.toString()}
    }).then(res => callback(res.data))
    .catch(err => callback(err.response.data))
  }

  submitNet = type => {
    if (type !== 'Wi-Fi') {
      this.submitNetworkSetting(null, null, type)
    }

    this.setState(prevState => {
      return {
        wan: type === 'Wi-Fi' ? true : false,
        networkSettings: {
          ...prevState.networkSettings,
          networkOption: type
        }
      }
    })
  }

  submitWan = (ssid, password) => {
    this.submitNetworkSetting(ssid, password, 'Wi-Fi')

    this.setState(prevState => {
      return {
        networkSettings: {
          ...prevState.networkSettings,
          ssid,
          password
        }
      }
    })
  }

  submitNetworkSetting = (ssid = null, password = null, networkOption = this.state.networkSettings.networkOption) => {
    this.setState({loading: true})
    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/set-wan',
      method: 'POST',
      withCredentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      },
      data: {networkOption, ssid, password}
    }).then(res => {
      this.setState({saved: true, access: false, wan: false, loading: false})
      this.props.reb()
    }).catch(err => this.setState({loading: false}))
  }

  render() {
    let content = <Pin submitPin={this.submitPin} error={this.state.accessError} formTitle={Strings['enterPin']} />

    if (this.state.loading) {
      content = <Loader />
    }else if(this.state.firstTime){
      content = <ChangePin submitPin={this.submitChangePin} error={this.state.accessError} formTitle={Strings['enterPin']} />
    }else if (this.state.wan) {
      content = <WANForm onSubmit={this.submitWan} def={this.props.netSettings} />
    }else if (this.state.access) {
      content = <NetForm onSubmit={this.submitNet} def={this.props.netSettings} />
    }else if (this.state.saved) {
      content = <Desc close={() => this.props.close()} title={Strings.networkSaved} body={this.state.networkSettings.networkOption === 'Wi-Fi'? Strings.networkBodyWifi : Strings.networkBodyOther} />
    }

    return (
      <Modal height={window.matchMedia('(max-width: 850px)').matches? 600 : 550}>
        <Close click={this.props.close} />
        {content}
      </Modal>
    )
  }
}

export default withRouter(WANScreen)