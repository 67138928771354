import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import userReducer from './reducers/user'
import homeCLUReducer from './reducers/homeCLU'
import modalReducer from './reducers/modal'
import ui from './reducers/ui'
import statusReducer from './reducers/status'

const rootReducer = combineReducers({
  user: userReducer,
  homeCLU: homeCLUReducer,
  ui,
  modal: modalReducer,
  status: statusReducer
})

const configureStore = () => {
  return createStore(rootReducer, compose(applyMiddleware(thunk)))
}

export default configureStore