import React from 'react'

// components
import Signal from '../../Signal/Signal'
import Offline from '../../Offline/Offline'
import Online from '../../Online/Online'

// styles
import Classes from './StatusListItem.css'

// assets
import Strings from '../../../assets/strings.json'

export default (props) => {
  let widget = null
  if (!props.data){
    return null
  }

  if (props.data.widget) {
    if (props.data.widget.type === 'signal') {
      widget = <Signal signal={props.data.widget.amount} />
    } else if (props.data.widget.type === 'offline') {
      widget = <Offline />
    } else if (props.data.widget.type === 'online')  {
      widget = <Online />
    }
  }

  return (
    <div className={Classes.StatusListItem}>
      {props.adTittle ? <h3 className={Classes.SectionTitle}>{Strings.lastConf}</h3>: null}
      <h4 className={[Classes.ItemTitle, props.change ? Classes.Smaller : null].join(' ')}>{props.data.name}</h4>
      <div className={Classes.ItemBody}>{props.data.value ? props.data.value : Strings.undefined} {widget}</div>
    </div>
  )
}
