import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'

// components
import Layout from '../../components/Layout/Layout'
import Link from '../../components/UI/Link/Link'
import Loader from '../../components/UI/Loader/Loader'
import StatusList from '../../components/StatusList/StatusList'
import WANScreen from '../WANScreen/WANScreen'
import Button from '../../components/UI/Button/Button'
import StatusListItem from '../../components/StatusList/StatusListItem/StatusListItem'

// actions
import {getStatus} from '../../store/actions/'

// styles
import Classes from './StatusScreen.css'

// assets
import logo from '../../assets/logo.png'
import {ReactComponent as NetworkIcon} from '../../assets/connection.svg'
import {ReactComponent as ProcIcon} from '../../assets/processor.svg'

// strings
import Strings from '../../assets/strings.json'

class StatusScreen extends Component {
  state = {
    wan: false,
    loading: true,
    enableButtons: false,
    data: {
      network: false
    },
    version: ''
  }

  componentDidMount() {
    if (window.matchMedia('(max-width: 1112px').matches){
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }else{
      document.querySelector('main').scrollTop = 0
    }

    this.props.getStatus()

    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/get-version',
      method: 'GET'
    }).then(res => {
      this.setState({ version: res.data['version'] });
    })
  }

  fetchData = () => {
    document.querySelector('main').scrollTop = 0

    this.props.getStatus()
  }

  // delNet = () => {
  //   this.setState({loading: true})
  //   fetch((process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/del-wan')
  //     .then(res => {
  //       return res.json();
  //     })
  //     .then(data => {
  //       this.fetchData()
  //     })
  // }

  render() {
    const Logo = <img src={logo} className={Classes.Logo} alt="Defa logo" />
    const Buttons = (<Fragment>
      <Button transparent={true} onClick={() => this.setState({wan: true})} icon={<NetworkIcon />}>{Strings.netConf}</Button>
      <Link to='/homeCLU' icon={<ProcIcon />}>{Strings.CLUConf}</Link>
      {/* <Button transparent={true} onClick={this.delNet}>Delete settings</Button> */}
    </Fragment>)

    let content = <Loader />

    let network = null
    let netData = null
    if (!this.props.loading) {
      content = <StatusList items={this.props.status} />
      netData = this.props.status.filter(it => it.name === 'CLU network connection')[0]
      network = <StatusListItem data={netData} />
    }

    return (
      <Fragment>
        <Layout top={Logo} bottom={Buttons} title="CLU Commissioning Tool" subtitle={this.state.version} middle={window.matchMedia('(max-width: 480px)').matches ? network : null}>
          {content}
        </Layout>
        {this.state.wan ? <WANScreen close={() => this.setState({wan: false})} reb={this.fetchData} netSettings={netData} /> : null}
      </Fragment>
    )
  }
}

const mapStateToPorps = state => ({
  status: state.status,
  loading: state.ui.loading
})

const mapDispatchToProps = dispatch => ({
  getStatus: () => dispatch(getStatus())
})

export default connect(mapStateToPorps, mapDispatchToProps)(StatusScreen)
