import React, { Component, Fragment } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

// components
import FormTitle from '../UI/FormTitle/FormTitle'
import Button from '../UI/Button/Button'
import Error from '../UI/Error/Error'

// styles
import Classes from './NetForm.css'

// assets
import Strings from '../../assets/strings.json'
import Settings from '../../assets/settings.json'

export default class NetForm extends Component {
  state = {
    ntype: {
      value: null,
      touched: false
    }
  }

  componentDidMount = () => {
    const def = this.props.def ? {value: this.props.def.settings.value.network, label: this.props.def.settings.value.network} : null

    this.setState({
      ntype: {
        value: def,
        touched: def ? true : false
      }
    })
  }
  

  selectChangeHandler = selectedOption => {
    this.setState({
      ntype: {
        value: selectedOption,
        touched: true
      }
    })
  }
  
  submitNet = e => {
    e.preventDefault()

    this.props.onSubmit(this.state.ntype.value.value)
  }
  
  render() {
    const options = Settings.networkTypes.map(type => { return {value: type, label: type} })

    const StyledSelect = styled(Select)`
      .react-select__control {
        border: 1px solid #E8E8E8;
        min-height: 50px;
        width: 300px;
        color: #000;
        outline: none;
        box-shadow: none;
      }

      .react-select__control--is-focused, .react-select__control--is-focused:hover {
        border-color: #E8E8E8;
        outline: none;
        box-shadow: none;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        padding-right: 20px;
        color: black;
      }

      .react-select__menu {
        margin-top: -4px;
        border-top: none;
        border-radius: 0px 0px 4px 4px;
        box-shadow: none;
        border-bottom: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        border-right: 1px solid #E8E8E8;
      }

      .react-select__menu-list {
        margin-top: 8px;
      }

      .react-select__value-container {
        padding: 2px 1.2em;
      }
      .react-select__option {
        padding: 1em 1.2em;
        text-align: left;
      }

      .react-select__option--is-selected {
        background: #F9F9F9;
        color: #000;
      }

      .react-select__option--is-focused {
        background: #FCFCFC;
        color: #000;
      }

      @media only screen and (max-width: 480px) {
        .react-select__control {
          width: 100%;
        }
      }
    `

    return (
      <Fragment>
        <FormTitle>{Strings.netTitle}</FormTitle>
        <form onSubmit={this.submitNet} className={Classes.NetForm}>
          <StyledSelect
            value={this.state.ntype.value}
            onChange={this.selectChangeHandler}
            options={options}
            className={Classes.Select}
            classNamePrefix='react-select'
            isSearchable={false}
          />
          <Button type="submit" disabled={!this.state.ntype.value || !this.state.ntype.touched} short={true}>{Strings.submit}</Button>
          {this.state.accessError? <Error>{Strings['error']}</Error>: null}
        </form>
      </Fragment>
    )
  }
}
