import React from 'react'

// styles
import Classes from './ReadOnly.css'

export default props => {
  return (
    <div className={Classes.ReadOnly}>{props.value}</div>
  )
}
