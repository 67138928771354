import React, { Component } from 'react'
import { connect } from 'react-redux'

// components
import Button from '../Button/Button'

// actions
import { modalClose } from '../../../store/actions'

// styles
import Classes from './Desc.css'

// assets
import Strings from '../../../assets/strings.json'

class Desc extends Component {
  closeModal = () => {
    this.props.closeModal()
  }
  
  render() {
    return (
      <div className={Classes.Desc}>
        <h3>{this.props.title || this.props.modal.title}</h3>
        <div className={Classes.Body} dangerouslySetInnerHTML={{__html: this.props.body || this.props.modal.body}} />
        <Button onClick={this.props.close || this.props.closeModal} short={true}>{Strings.submit}</Button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(modalClose())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Desc)

