import React from 'react'

// styles
import Classes from './DescButton.css'

export default props => {
  return (
    <div onClick={props.click} className={[Classes.Description, props.descPos === 'inline' ? Classes.Inline : Classes.Under, window.matchMedia('(max-width: 850px)').matches && props.mobile?Classes.Mobile:null].join(' ')}>?</div>
  )
}
