import React from 'react'

// styles
import Classes from './TextField.css'

export default (props) => {
  return (
    <input onChange={props.onChange} value={props.value} type={props.type} name={props.name} className={[Classes.TextField, Classes[props.id]].join(' ')} />
  )
}
