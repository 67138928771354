import React from 'react'
import { Link } from 'react-router-dom'

// styles
import Classes from './Link.css'

export default (props) => {
  return (
    <Link className={[Classes.Link, props.disabled ? Classes.Disable : null].join(' ')} to={props.to}>
      {props.icon}
      {props.children}
    </Link>
  )
}
