import React from 'react'

// styles
import Classes from './FormTitle.css'

export default (props) => {
  return (
    <h2 className={Classes.Title}>
      {props.children}
    </h2>
  )
}
