import React, { Component, Fragment } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import Axios from 'axios'

// components
import Loader from '../UI/Loader/Loader'
import FormTitle from '../UI/FormTitle/FormTitle'
import FormFields from '../UI/FormFields/FormFields'
import Button from '../UI/Button/Button'
import Error from '../UI/Error/Error'
import Signal from '../Signal/Signal'

// styles
import Classes from './WANForm.css'

// assets
import Strings from '../../assets/strings.json'

export default class WANForm extends Component {
  state = {
    loading: true,
    fields: {
      ssid: {
        value: null,
        touched: false,
        error: false,
        options: []
      },
      password: {
        value: '',
        touched: false
      }
    },
    accessError: false
  }

  componentDidMount = () => {
    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/get-wan-temp',
      method: 'GET'
    }).then(res => {
      this.setState(prevState => { 
        const options = res.data.ssids.map(option => ({
          value: option.ssid,
          label: <div className={Classes.SelectRow}>{option.ssid} <Signal signal={option.rssi} /></div>
        }))

        const def = this.props.def.settings.value.ssid ? options.filter(it => it.value === this.props.def.settings.value.ssid)[0] : null

        return {
          loading: false,
          fields: {
            ...prevState.fields,
            ssid: {
              ...prevState.fields.ssid,
              value: def,
              touched: def ? true : false,
              options
            }
          }
        }
      })
    }).catch(err => console.log(err))
  }

  selectChangeHandler = selectedOption => {
    this.setState(prevState => { 
      return {
        fields: {
          ...prevState.fields,
          ssid: {
            ...prevState.fields.ssid,
            value: selectedOption,
            touched: true
          }
        }
      }
    })
  }

  passwordChangeHandler = value => {
    if (value.length>0) {
      this.setState(prevState => {
        return {
          fields: {
            ...prevState.fields,
            password: {
              error: false,
              value,
              touched: true
            }
          }
        }
      })
    }else{
      this.setState(prevState => {
        return {
          fields: {
            ...prevState.fields,
            password: {
              ...prevState.fields.password,
              value,
              error: Strings.notEmpty
            }
          }
        }
      })
    }
    
  }
  

  submitWAN = e => {
    e.preventDefault()

    this.props.onSubmit(this.state.fields.ssid.value.value, this.state.fields.password.value, () => this.setState({accessError: true}))
  }

  render() {
    let content = <Loader />

    if(!this.state.loading) {
      const customStyles = {
        singleValue: (provided, state) => {
          return { ...provided, width: '95%' };
        }
      }

      const StyledSelect = styled(Select)`
      .react-select__control {
        border: 1px solid #E8E8E8;
        min-height: 50px;
        width: 300px;
        color: #000;
        outline: none;
        box-shadow: none;
      }

      .react-select__control--is-focused, .react-select__control--is-focused:hover {
        border-color: #E8E8E8;
        outline: none;
        box-shadow: none;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        padding-right: 20px;
        color: black;
      }

      .react-select__menu {
        margin-top: -4px;
        border-top: none;
        border-radius: 0px 0px 4px 4px;
        box-shadow: none;
        border-bottom: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        border-right: 1px solid #E8E8E8;
      }

      .react-select__menu-list {
        margin-top: 8px;
      }

      .react-select__option>div {
        display: flex;
        justify-content: space-between;
      }

      .react-select__single-value>div {
        display: flex;
        justify-content: space-between;
      }

      .react-select__single-value>div>div {
        margin-right: 1em;
      }

      .react-select__option--is-selected {
        background: #F9F9F9;
        color: #000;
      }

      .react-select__value-container {
        padding: 2px 1.2em;
      }

      .react-select__option {
        padding: 1em 3.7em 1em 1.2em;
        text-align: left;
      }

      .react-select__option--is-focused {
        background: #FCFCFC;
        color: #000;
      }

      @media only screen and (max-width: 480px) {
        .react-select__control {
          width: 100%;
        }
      }
    `
      content = (
        <Fragment>
          <FormTitle>{Strings.wanTitle}</FormTitle>
          <form onSubmit={this.submitWAN}>
            <StyledSelect
              styles={customStyles}
              value={this.state.fields.ssid.value}
              onChange={this.selectChangeHandler}
              options={this.state.fields.ssid.options}
              classNamePrefix='react-select'
              placeholder={Strings.selectWifi}
              isSearchable={false}
            />
            <FormFields error={this.state.fields.password.error} touched={this.state.fields.password.touched} value={this.state.fields.password.value} type="password" name="password" onChange={this.passwordChangeHandler} title={Strings['enterPass']} />
            <Button type="submit" disabled={this.state.fields.ssid.error || !this.state.fields.ssid.touched || this.state.fields.password.error || !this.state.fields.password.touched} short={true}>{Strings.submit}</Button>
            {this.state.accessError? <Error>{Strings['error']}</Error>: null}
          </form>
        </Fragment>
      )
    }
    return content
  }
}
