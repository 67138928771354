import React, { Component } from 'react'
import Select from 'react-select'
import _ from 'lodash'
import styled from 'styled-components'

export default class SelectElement extends Component {
  state = {
    value: ''
  }

  componentDidMount = () => {
    
  }

  selectChangeHandler = value => {
    this.setState({value})
    this.props.onChange(value)
  }

  render () {
    const options = []
    _.each(this.props.options, option => {
      if (typeof option.label === 'object') {
        if(option.label.show){
          options.push({value: option.value, label: option.label.label})
        }
      }else{
        options.push({value: option.value, label: option.label})
      }
    })

    let val = ''
    if (this.props.value) {
      
      this.props.options.forEach(el => {
        if (el.value === this.props.value) {
          val = {value: el.value, label: typeof el.label === 'object' ? el.label.label : el.label}
        }
      })
    }

    const StyledSelect = styled(Select)`
      .react-select__control {
        border: 1px solid #E8E8E8;
        min-height: 50px;
        width: 440px;
        color: #000;
        outline: none;
        box-shadow: none;
      }

      .react-select__control:hover {
        border-color: #b3b3b3;
      }

      .react-select__control--is-focused, .react-select__control--is-focused:hover {
        border-color: #E8E8E8;
        outline: none;
        box-shadow: none;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        padding-right: 20px;
        color: black;
      }

      .react-select__value-container {
        padding: 2px 1.2em;
      }

      .react-select__menu {
        margin-top: -4px;
        border-top: none;
        border-radius: 0px 0px 4px 4px;
        box-shadow: none;
        border-bottom: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        border-right: 1px solid #E8E8E8;
      }

      .react-select__menu-list {
        margin-top: 8px;
      }

      .react-select__option {
        padding: 1em 1.2em;
      }

      .react-select__option--is-selected {
        background: #F9F9F9;
        color: #000;
      }

      .react-select__option--is-focused {
        background: #FCFCFC;
        color: #000;
      }

      @media only screen and (max-width: 480px) {
        .react-select__control {
          width: 100%;
        }
      }
    `

    return (
      <StyledSelect 
        value={val}
        onChange={this.selectChangeHandler}
        options={options}
        classNamePrefix='react-select'
        isSearchable={false}
      />
    )
  }
}
