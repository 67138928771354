import React, { Component, Fragment } from 'react'

// components
import FormFields from '../UI/FormFields/FormFields'
import Button from '../UI/Button/Button'
import FormTitle from '../UI/FormTitle/FormTitle'
import Error from '../UI/Error/Error'

// style
import Classes from './ChangePin.css'

// assets
import Strings from '../../assets/strings.json'

export default class ChangePin extends Component {
  state = {
    pin: {
      value: '',
      touched: false,
      error: null
    },
    checkPin: {
      value: '',
      touched: false,
      error: null
    }
  }

  pinChangeHandler = pin => {
    if (pin.length === 4) {
      if (pin.match(/^\d+$/)){
        this.setState({
          pin: {
            value: pin,
            touched: true,
            error: false
          },
          checkPin: {
            ...this.state.checkPin,
            touched: false
          }
        })
      }else{
        this.setState({
          pin: {
            value: pin,
            touched: true,
            error: 'Only numbers'
          },
          checkPin: {
            ...this.state.checkPin,
            touched: false
          }
        })
      }
    } else {
      this.setState({
        pin: {
          value: pin,
          touched: true,
          error: 'Must be 4 digits'
        },
        checkPin: {
          ...this.state.checkPin,
          touched: false
        }
      })
    }
  }

  pinCheckChangeHandler = pin => {
    if (pin === this.state.pin.value) {
      this.setState({
        checkPin: {
          value: pin,
          touched: true,
          error: false
        }
      })
    } else {
      this.setState({
        checkPin: {
          value: pin,
          touched: true,
          error: Strings.mustMatch
        }
      })
    }
  }

  submitPin = (e) => {
    e.preventDefault()

    this.props.submitPin(this.state.pin.value)
  }

  render() {
    return (
      <Fragment>
        <FormTitle>{this.props.formTitle}</FormTitle>
        <form onSubmit={this.submitPin}>
          <div className={Classes.FieldRow}>
            <FormFields error={this.state.pin.error} touched={this.state.pin.touched} placeholder="Pin" value={this.state.pin.value} type="password" name="pin" onChange={this.pinChangeHandler} title={Strings['newPin']} />
            <FormFields error={this.state.checkPin.error} touched={this.state.checkPin.touched} placeholder="Retype pin" value={this.state.checkPin.value} type="password" name="checkPin" onChange={this.pinCheckChangeHandler} title={Strings['retypePin']} />
          </div>
          <Button short={true} type="submit" disabled={this.state.pin.error || !this.state.pin.touched || this.state.checkPin.error || !this.state.checkPin.touched}>{Strings['save']}</Button>
          {this.props.error? <Error>{Strings['error']}</Error>: null}
        </form>
      </Fragment>
    )
  }
}