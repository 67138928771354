import {CANCEL_ADDING_CHARGE_POINT, GET_HOMECLU_SETTINGS, ADD_CHARGE_POINT, START_ADDING_CHARGE_POINT, DELETE_CHARGE_POINT, UPDATE_CHARGE_POINT, ERROR_HOMECLU_SETTINGS} from './actionTypes'
import {modalClose} from './modal'
import {UIStartLoading, UIStopLoading} from './ui'
import Axios from 'axios'

export const getSettings = () => {
  return dispatch => {
    dispatch(UIStartLoading())

    Axios({
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/get-homeCLU',
      method: 'GET'
    }).then(res => {
      dispatch(setSettings(res.data))
      dispatch(UIStopLoading())
    }).catch(err => {
      console.log(err)
      dispatch(UIStopLoading())
    })
  }
}

export const addCP = (data, key) => {
  return {
    type: ADD_CHARGE_POINT,
    chargePoint: data,
    cpForm: key
  }
}

export const startAddingCP = (key = true) => {
  return {
    type: START_ADDING_CHARGE_POINT,
    cpForm: key
  }
}

export const cancelAddingCP = () => {
  return dispatch => {
    dispatch(modalClose())
    dispatch(closeCP())
  }
}

export const closeCP = () => {
  return {
    type: CANCEL_ADDING_CHARGE_POINT,
    cpForm: false
  } 
}

export const deleteCP = () => {
  return {
    type: DELETE_CHARGE_POINT
  }
}

export const updateCP = data => {
  return {
    type: UPDATE_CHARGE_POINT,
    settings: data
  }
}

export const setSettings = (data, undo = false) => {
  return {
    type: GET_HOMECLU_SETTINGS,
    settings: data,
    undo
  }
}

export const errorSettings = msg => {
  return {
    type: ERROR_HOMECLU_SETTINGS,
    error: msg
  }
}


export const updateSettings = (settings, user) => {
  return dispatch => {
    dispatch(UIStartLoading())

    const payload = {settings, user}

    dispatch(setSettings(payload.settings))
    dispatch(errorSettings(false))
    Axios({
      url:(process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '') + '/set-homeCLU',
      method: 'POST',
      withCredentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      },
      data: payload
    }).then(_ => {
      window.location.href = '/'
      dispatch(UIStopLoading())
    }).catch(err => {
      dispatch(errorSettings(err.response.data.msg))
      dispatch(UIStopLoading())
    })
  }
}