export const ADD_CHARGE_POINT = 'ADD_CHARGE_POINT'
export const DELETE_CHARGE_POINT = 'DELETE_CHARGE_POINT'
export const START_ADDING_CHARGE_POINT = 'START_ADDING_CHARGE_POINT'
export const CANCEL_ADDING_CHARGE_POINT = 'CANCEL_ADDING_CHARGE_POINT'
export const UPDATE_CHARGE_POINT = 'UPDATE_CHARGE_POINT'

export const GET_HOMECLU_SETTINGS = 'GET_HOMECLU_SETTINGS'
export const UPDATE_HOMECLU_SETTINGS = 'UPDATE_HOMECLU_SETTINGS'
export const ERROR_HOMECLU_SETTINGS = 'ERROR_HOMECLU_SETTINGS'

export const AUTH_USER = 'AUTH_USER'
export const ADD_USER = 'ADD_USER'
export const GET_USER = 'GET_USER'
export const ERROR_USER = 'ERROR_USER'

export const UI_START_LOADING = 'UI_START_LOADING'
export const UI_STOP_LOADING = 'UI_STOP_LOADING'

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'

export const SAVE_STATUS = 'SAVE_STATUS'
export const GET_STATUS = 'GET_STATUS'